import { useState, useEffect } from 'react';
import { GameRow } from './components/game_pieces';
import { handleResponse } from '../_helpers/handle-response';
import Keyboard from './components/keyboard';

import { trackGoal } from 'fathom-client';
import { Header } from './components/header';
import { HelpPanel } from './help_panel';
import WarningDialog from './components/WarningDialog';

const newGameboardRow = (i) => {
  return {
    row: i, squares: [
      { loc: 0, letter: "", state: "unknown" },
      { loc: 1, letter: "", state: "unknown" },
      { loc: 2, letter: "", state: "unknown" },
      { loc: 3, letter: "", state: "unknown" },
      { loc: 4, letter: "", state: "unknown" },
    ]
  }
}

function Wordle() {

  const [gameboard, setGameboard] = useState([
    newGameboardRow(0),
  ])
  const [suggestedWords, setSuggestedWords] = useState([])
  const [missingLetters, setMissingLetters] = useState([])
  const [warningIsOpen, setWarningIsOpen] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [helpVisible, setHelpVisible] = useState(false)

  useEffect(() => {
    if (!localStorage.noFirstVisit) {
      setHelpVisible(true);

      // check this flag for escaping this if block next time
      localStorage.noFirstVisit = true;
    }
  }, [])

  const resetBoard = () => {
    setGameboard([newGameboardRow(0)]);
    setSuggestedWords([]);
    setMissingLetters([]);
    setUpdated(false);
    trackGoal("DFD9IEYP");
  }

  const showHelp = () => {
    setHelpVisible(true);
    trackGoal("E9CPVGOE");
  }
  const closeHelp = () => { setHelpVisible(false); }

  const onLetterSelect = (letter) => {
    let newGameboard = [...gameboard];
    let currentGameboardRow = newGameboard.length - 1;

    let newRow = newGameboard[currentGameboardRow];
    for (var i = 0; i < newRow.squares.length; i++) {
      let square = newRow.squares[i];
      if (square.letter === "") {
        newRow.squares[i].letter = letter;
        newGameboard[currentGameboardRow] = newRow;
        setGameboard(newGameboard);
        setUpdated(true);
        return
      }
    }
  }

  const onCommandSelect = (cmd) => {
    let currentGameboardRow = gameboard.length - 1;

    switch (cmd) {
      case "Del":
        let newGameboard = [...gameboard];

        let newRow = newGameboard[currentGameboardRow];
        for (var i = newRow.squares.length - 1; i >= 0; i--) {
          let square = newRow.squares[i];
          if (square.letter !== "") {
            newRow.squares[i].letter = "";
            newGameboard[currentGameboardRow] = newRow;
            setGameboard(newGameboard);
            setUpdated(true);
            return
          }
        }
        break;

      case "Enter":
        let row = gameboard[currentGameboardRow];
        if (currentGameboardRow === 0 && row.squares[row.squares.length - 1].letter === "") { return }
        if (!updated) { return }

        submitLetters();
        break;

      default:
        break;
    }
  }

  const submitLetters = () => {
    let fullRows = true;
    // Combine all squares into API compatible letter entries
    let letters = {};
    for (let iRow = 0; iRow < gameboard.length; iRow++) {
      let squares = gameboard[iRow].squares;
      for (let iSquare = 0; iSquare < squares.length; iSquare++) {
        let square = squares[iSquare];
        let letterObj = letters[square.letter] || {};
        letterObj.letter = square.letter;

        switch (square.state) {
          case "correct":
            letterObj.contained = true;
            letterObj.confirmed_spaces = [...letterObj.confirmed_spaces || [], iSquare];
            break;
          case "misplaced":
            letterObj.contained = true;
            letterObj.eliminated_spaces = [...letterObj.excluded || [], iSquare];
            break;
          case "excluded":
            letterObj.contained = false;
            letterObj.eliminated_spaces = [0, 1, 2, 3, 4];
            break;
          default:
            if (square.letter) {
              setWarningIsOpen(true);
              return
            }

            fullRows = false;
            continue;
        }

        letters[square.letter] = letterObj;
      }
    }

    // Build Array for API endpoint
    let letterArray = []
    for (const k in letters) {
      let letter = letters[k];
      letterArray = [...letterArray, letter]
    }
    console.log(letterArray);

    // Submit to endpoint
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(letterArray)
    };
    let url = new URL("https://wordle-hints-go-shpaneodja-uc.a.run.app/api/letters");
    // let url = new URL("http://localhost:8001/api/letters");
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        console.log(response)
        setSuggestedWords(response.words);
        setMissingLetters(response.missing_letters);
        setUpdated(false);
        if (fullRows) {
          setGameboard([...gameboard, newGameboardRow(gameboard.length)])
        }
        trackGoal("SACIXSZK");
        return response;
      });
  }

  const setSquares = (row, squares) => {
    let newGameboard = [...gameboard];
    let newRow = newGameboard[row];
    newRow.squares = squares;
    newGameboard[row] = newRow;
    setGameboard(newGameboard);
    setUpdated(true);
  }

  return (
    <div className="flex flex-col">
      <HelpPanel helpVisible={helpVisible} closeHelp={closeHelp} />
      <Header showHelp={showHelp} resetBoard={resetBoard} />
      <div className="flex-1 grow space-y-1.5 pt-4 pb-6">
        {gameboard.map((line) => (
          <GameRow
            key={`row-${line.row}`}
            row={line.row}
            squares={line.squares}
            setSquares={setSquares} />
        ))}
      </div>
      <div>
        <Suggestions words={suggestedWords} missingLetters={missingLetters} />
      </div>
      <div className="mt-10 pb-2">
        <Keyboard onLetterSelect={onLetterSelect} onCommandSelect={onCommandSelect} />
      </div>
      <WarningDialog isOpen={warningIsOpen} setIsOpen={setWarningIsOpen} />
    </div>
  );
}

function Suggestions({ words, missingLetters }) {

  let missingArray = [];
  let totalLetterInstances = 0;
  for (var letter in missingLetters) {
    missingArray.push([letter, missingLetters[letter]])
    totalLetterInstances += missingLetters[letter];
  }

  missingArray.sort(function (a, b) {
    return b[1] - a[1];
  });

  return (
    <div>
      {words.length === 0
        ?
        <div className="text-lg font-semibold text-center max-w-sm">
          Touch the letter boxes above to select the correct colors for your search
        </div>
        :
        <div>
          <div className="ml-5 font-semibold">
            Possible Words Found: {words.length}
          </div>
          <div className="ml-5 mb-1 font-semibold">
            The answer most likely contains the letter:
          </div>
          <div className="grid grid-cols-4">
            {missingArray.map((obj) => (
              <div className="flex justify-between mx-5">
                <div className="uppercase font-semibold">{obj[0]}:</div>
                <div>{Math.trunc((obj[1] / totalLetterInstances) * 100)}%</div>
              </div>
            ))}

            {/* {words.map((word) => (
              <div key={word} className="uppercase mx-5">{word}</div>
            ))} */}
          </div>
        </div>
      }
    </div>
  )
}

export default Wordle;
