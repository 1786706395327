function Keyboard({ onLetterSelect, onCommandSelect }) {
  const keys = ["qwertyuiop", "asdfghjkl", "zxcvbnm"];

  return (
    <div className="space-y-1">
      <div className="flex justify-center items-center space-x-1.5">
        {keys[0].split("").map((letter) => (
          <Key key={`key-${letter}`} letter={letter} onLetterSelect={onLetterSelect} />
        ))}
      </div>

      <div className="flex justify-center items-center space-x-1.5">
        {keys[1].split("").map((letter) => (
          <Key key={`key-${letter}`} letter={letter} onLetterSelect={onLetterSelect} />
        ))}
      </div>

      <div className="flex justify-center items-center space-x-1.5">
        <Key cmd={"Enter"} onLetterSelect={onCommandSelect} />
        {keys[2].split("").map((letter) => (
          <Key key={`key-${letter}`} letter={letter} onLetterSelect={onLetterSelect} />
        ))}
        <Key cmd={"Del"} onLetterSelect={onCommandSelect} />
      </div>
    </div>
  )
}

function Key({ letter, cmd, onLetterSelect }) {
  if (letter) {
    return (
      <button onClick={() => { onLetterSelect(letter) }} className="flex justify-center items-center h-14 w-8 p-1 bg-gray-200 text-black rounded uppercase active:bg-gray-600 transition-colors ease-in-out select-none">
        {letter}
      </button>
    )
  }

  if (cmd) {
    return (
      <button onClick={() => { onLetterSelect(cmd) }} className="flex justify-center items-center h-14 w-14 bg-gray-200 text-black rounded uppercase active:bg-gray-600 transition-colors ease-in-out select-none">
        {cmd}
      </button>
    )
  }
}

export default Keyboard;