import { XIcon } from '@heroicons/react/outline'

export function HelpPanel({ helpVisible, closeHelp }) {

  return (
    <div className={`${helpVisible ? null : 'hidden'} absolute top-0 left-0 h-full w-full flex justify-center items-center`}>
      <div className="absolute z-0 h-full w-full bg-gray-500 bg-opacity-40" onClick={closeHelp} ></div>
      <div className="z-10 bg-white w-full h-fullx sm:h-160 max-h-screen max-w-sm">

        {/* HEADER */}
        <div className="relative">
          <XIcon className="absolute right-0 top-0 h-6 m-2 cursor-pointer" onClick={closeHelp} />
          <h2 className="flex-1 text-center py-2 font-bold">
            Help using Wordle Hints
          </h2>
        </div>

        {/* BODY */}
        <div className="m-4 pb-5 space-y-3 text-sm font-semibold border-b">
          <p>
            <a href="https://www.nytimes.com/games/wordle/index.html" className="underline text-indigo-500" target="_blank" rel="noopener noreferrer">Wordle</a> is a fun game, but sometimes you need a little nudge in the right direction.
          </p>
          <hr />
          <p>Type any words you've already guessed.</p>
          <p>Click each letter box to set the correct color.</p>
          <p>Press ENTER to submit your letters.</p>
          <p>Wordle Hints searches and gives you a list of letters and the likelyhood they are in the correct answer.</p>
        </div>

        {helpVisible ?
          <video autoPlay muted loop disableRemotePlayback class="mx-auto mb-5">
            <source src="/videos/wordle-hints-demo-1mbit.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          : null}
      </div>
    </div >
  );
}
