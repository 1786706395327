import { Dialog } from '@headlessui/react'

function WarningDialog({ isOpen, setIsOpen }) {

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="text-black fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />

        <div className="relative bg-white rounded border border-gray-300 max-w-sm mx-auto p-4">
          <Dialog.Title className="font-semibold border-b border-gray-300 pb-2 mb-4">
            Could not perform search
          </Dialog.Title>

          <Dialog.Description className="text-sm mb-4">
            You must tap the letters to set a color for every square before hitting ENTER
          </Dialog.Description>

          {isOpen ?
            <video autoPlay muted loop disableRemotePlayback class="mx-auto mb-5">
              <source src="/videos/wordle-letter-taps.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            : null}

          <div className="flex justify-end mt-4">
            <button className="bg-blue-600 text-white font-semibold rounded mx-1 px-6 py-1" onClick={() => setIsOpen(false)}>Close</button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default WarningDialog;
