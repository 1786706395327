import { QuestionMarkCircleIcon, RefreshIcon } from '@heroicons/react/outline'

export function Header({ showHelp, resetBoard }) {
  return (
    <div className="flex justify-between items-center border-b py-1 px-2.5">
      <div>
        <QuestionMarkCircleIcon className="h-5 text-gray-600 cursor-pointer" onClick={showHelp} />
      </div>
      <div className="font-bold text-lg">
        Wordle Hints
      </div>

      <div>
        <RefreshIcon className="h-5 text-gray-600 cursor-pointer" onClick={resetBoard} />
      </div>
    </div>
  );
}
