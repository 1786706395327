import React, { useEffect } from 'react';
import * as Fathom from 'fathom-client';

import Wordle from './pages/wordle';

function App() {

  useEffect(() => {
    if (process.env.NODE_ENV === "development") { return; }

    Fathom.load('RDLKCIER', {
      url: "https://neon-generous.wordlehints.com/script.js",
      // auto: false,
      spa: "auto",
    });
  }, []);

  return (
    <Wordle />
  );
}

export default App;
