
export function handleResponse(response) {
    return response.text().then(text => {
        let data;
        try {
            data = JSON.parse(text);
        } catch (err) {
            data = text;
        }

        if (!response.ok) {
            const error = (data && data.message) || `${response.statusText}: ${data}`;
            return Promise.reject(error);
        }

        return data;
    });
}